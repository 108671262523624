import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import queryString from 'query-string';
const redirectImage = require('./redirect-image.jpg');

export const RedirectComponent = () => {
    const [seconds, setSeconds] = useState(3);
    const queryValues = queryString.parse(window.location.search);
    const url = process.env.REACT_APP_REDIRECT_URL?.toString() ?? '';
    let redirectURL = url;

    if (queryValues?.c) {
        redirectURL = `${url}/?c=${queryValues.c}`;
    }

    const interval = setInterval(() => {
        updateSeconds();
    }, 1000);

    const updateSeconds = () => {
        if (seconds === 1) {
            clearInterval(interval);
            window.location.href = redirectURL;
        } else {
            setSeconds(seconds - 1);
        }
    }

    const Content = () => {
        return <>
            <span>Dittkort.se har ny design.</span>
            <span>Du slussas vidare inom {seconds}s.</span>
            <span className='content-mob'>Klicka <a style={{ color: '#C5A05B' }} href={redirectURL}>här</a> om så inte sker.</span>
        </>
    }

    return (
        <>
            <Container open={true}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div className="container">
                    <div className='text-cont'>
                        <div className='first-title'>Ny design
                        </div>
                        <div className='content'>
                            <Content />
                        </div>
                    </div>
                    <img className="image" src={redirectImage} />
                    <div className='content-mobile'>
                        <Content />
                    </div>
                </div>
            </Container>
        </>)
}


const Container = styled(Modal)`
margin:auto !important;
width: 895px;
height: 604px;

.container{
  background:white;
  display:flex;
  flex-direction:row;
  .text-cont{
    display: flex;
    min-width: 310px;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;
    background: white;
    .first-title{
        font-family: 'Shippori Mincho B1', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 39px;
        line-height: 61px;      
        margin-bottom: 50px;
        color: #403F3B;
    }
    .content{
        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 39px;
        display:flex;
        flex-direction:column;
        color: #403F3B;
    }
}
.image{
}
.content-mobile{
    display:none;
}
  
}


@media (max-width:480px){
    width: 80%;
    .container{
    height :75vh;
    flex-direction:column;
    width: 100%;
    justify-content: space-evenly;
    .content-mob{
        display:block;
    }
    
.text-cont{
    .first-title{
        margin-bottom: 0px;
        color: #403F3B;
    }
    padding-left:0px;
    align-items:center;
    .content{
        display:none;
    }
}
    .image{
        width: 85%;
    }
  
    
    .content-mobile{
        display:flex;
        align-self:center;
        flex-direction:column;
        width:62%;
        color: #403F3B;
        line-height: 21px;
        & > span {
            text-align:center;
        }
    }}
`;